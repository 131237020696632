import { required, maxLength, } from "vuelidate/lib/validators";
import { DateFormater } from "@/_helpers/funciones";
import { onlyAlphanumeric4 } from "@/_validations/validacionEspeciales";
    export const FormDelay = (FgHatch, FgHold, FgTwinBox, FgMachine, FgObservation, fromDay, toDay, laterDate, previousDate, itinerarySelected) => {
        let Hatch = FgHatch ? { required } : false;
        let Hold = FgHold ? { required } : false;
        let TwinBox = FgTwinBox ? { required, maxLength: maxLength(3) } : false;       
        let Machine = FgMachine ? { required } : false;
        let Observation = FgObservation ? { required, maxLength: maxLength(250), onlyAlphanumeric4 } : false;     
        return {
            delayCustom: { required },
            FormDelay: {
                VisitCraneId: { required },
                dateBegin: { required, TimeValidate(value){
                        if (itinerarySelected.Atd!=undefined) {
                            return DateFormater.formatDateTimeWithoutSlash(value) >= previousDate &&
                                DateFormater.formatDateTimeWithoutSlash(value) <= laterDate
                        } else {
                            let currentDate = DateFormater.formatDateTimeWithoutSlash((new Date))
                            return DateFormater.formatDateTimeWithoutSlash(value) >= previousDate &&
                                DateFormater.formatDateTimeWithoutSlash(value) <= currentDate
                        }
                    }, RangeValidate (value) {
                        if (toDay) {
                            return DateFormater.formatDateTimeWithoutSlash(value) < DateFormater.formatDateTimeWithoutSlash(toDay)
                        }else{
                            return true
                        }  
                    } 
                },
                dateFinish: { TimeValidate(value){
                        if (value) {
                            if (itinerarySelected.Atd!=undefined){
                                return DateFormater.formatDateTimeWithoutSlash(value) <= laterDate &&
                                    DateFormater.formatDateTimeWithoutSlash(value) >= previousDate
                            }else{
                                let currentDate = DateFormater.formatDateTimeWithoutSlash((new Date))
                                return DateFormater.formatDateTimeWithoutSlash(value) >= previousDate &&
                                    DateFormater.formatDateTimeWithoutSlash(value) <= currentDate
                            }
                        }else{
                            return true;
                        }
                    }, RangeValidate (value) {
                        if (fromDay&&value) {
                            return DateFormater.formatDateTimeWithoutSlash(value) > DateFormater.formatDateTimeWithoutSlash(fromDay)
                        }else{
                            return true
                        }  
                    } 
                }, 

                vesselHoldId: Hold,
                observation: Observation,
                fgApplyTdr: { },
                quantity: TwinBox,
                machineId: Machine,
                
                vesselHoldHatchCId: Hatch,
                fgApplyTdr: { },

                date: {  },
                time: {  },
            }
        }
    }